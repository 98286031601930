<template>
<div classs="bg-white">
  <div v-if="loading" class="text-white text-center">
    <transition name="fade">
      <div class="text-white text-center" style="background-image: url('/img/Abstract3drender-small.jpg'); background-repeat: no-repeat; background-size: cover; position: fixed; z-index: 10000; top: 66px; right: 0px; bottom: 0px; left: 0px;">
        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top justify-content-between navbar-top" style="height: 80px;">
          <img :src="$config.BASE_URL + '/lg.jpg'" width="107.5" height="43" />
        </nav>
        <h1 class="kstyle title justify-content-md-center" style="margin-top: 100px;">Loading cases</h1>
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 6em; margin-top: 100px;"></i>
        <div style="font-size: 1.5em; margin-top: 100px;">loaded: {{count}} cases</div>
      </div>
    </transition>
  </div>
  <b-container fluid v-if="!loading" class="bg-white" style="margin: 0px; padding: 0px;">
      <b-row style="background: #1E49E2; padding: 0px; height: 57px;">
        <b-col style="background: #1E49E2; height: 57px;">
          <!-- <div style="background: #1E49E2; font-size: 16px; color: white; height: 57px;"> -->
          <b-navbar style="background: #1E49E2; height: 57px;">
            <b-navbar-nav>
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item :to="{ name: 'Mar' }" exact exact-active-class="active">Home</b-nav-item>
                <b-nav-item :to="{ name: 'MarCases' }" exact exact-active-class="active">Cases</b-nav-item>
                <b-nav-item :to="{ name: 'MarRisks' }" exact exact-active-class="active">Risks</b-nav-item>
                <b-nav-item :to="{ name: 'MarNews' }" exact exact-active-class="active">News</b-nav-item>
                <b-nav-item :to="{ name: 'MarInsights' }" exact exact-active-class="active">Insights</b-nav-item>
                <b-nav-item v-if="$config.BUILD === 'full'" :to="{ name: 'MarDataStrats' }" exact exact-active-class="active">Data stratification</b-nav-item>
                <b-nav-item v-if="permissions.add" :to="{ name: 'FormNew', params: { model: 'case' }}" exact exact-active-class="active">New case</b-nav-item>
              </b-nav>
            </b-navbar-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav pills id="top-menu-bar" style="background: #1E49E2; font-size: 16px; color: white; height: 57px; line-height: 43px;">
                <b-nav-item v-if="loadingBackground">Reloading {{ count }} records</b-nav-item>
                <b-nav-item @click="reload" v-b-popover.hover.top="'Reload data'">Reload <b-icon-arrow-clockwise v-if="!loadingBackground"></b-icon-arrow-clockwise><b-icon-arrow-clockwise animation="spin" v-if="loadingBackground"></b-icon-arrow-clockwise></b-nav-item>
                <b-nav-item >{{ cases.length}} cases loaded</b-nav-item>
              </b-nav>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row v-if="ready">
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import PouchDB from 'pouchdb'
import workflow from '@/workflows/case'
import { BIconArrowClockwise } from 'bootstrap-vue'

let db = new PouchDB('cases')

export default {
  name: 'MarLayout',
  components: {
    BIconArrowClockwise
  },
  computed: {
    cases: {
      get () {
        return this.$store.state.cases
      },
      set (payload) {
        this.$store.commit('setCases', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    document.title = "KPMG Market Abuse Insights"
    this.$store.commit('setBrand', 'Market Abuse Insights')
    this.$stat.log({ page: 'mar', action: 'open mar' })
    if (this.$config.BUILD === 'external') {
      this.permissions.add = false
    } else {
      this.permissions.add = ac.can(this.user.acgroups).createAny('case').granted
    }
    await this.load()
  },
  data () {
    const data = {
      count: 0,
      loading: true,
      loadingBackground: false,
      permissions: {
        add: false
      },
      ready: false,
      style: ''
    }
    return data
  },
  methods: {
    load: async function () {
      this.loading = true
      this.ready = false
      let loadFromDb = false
      let reloadFromDb = false
      const infoDb = await db.info()
      if (this.cases.length === 0) {
        if (infoDb.doc_count === 0) {
          loadFromDb = true
        } else {
          let cases = await db.allDocs({ include_docs: true })
          cases = cases.rows.map(x => x.doc)
          this.count = cases.length
          this.cases = cases
          reloadFromDb = true
        }
      }
      if (loadFromDb) {
        await Promise.all([this.loadRows()])
        this.loading = false
        this.ready = true
      } else if (reloadFromDb) {
        this.loading = false
        this.ready = true
        this.loadingBackground = true
        await Promise.all([this.loadRows()])
        this.ready = false
        this.loadingBackground = false
        this.ready = true
      } else {
        this.loading = false
        this.ready = true
      }
    },
    loadRows: async function () {
      let rows = []
      try {
        const limit = 50
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          response = await this.$Amplify.API.get('cosmos', `/cases/limit/${limit}/offset/${offset}`)
          response.forEach(x => {
            const w = workflow.find(y => y.id === x.status_id)
            x.workflow = [{
              id: x.status_id,
              name: w.name
            }]
            if (x.publications.length > 0) {
              x.date_published = x.publications[0].dateTime
              const d = new Date(x.date_published)
              x.year = d.getFullYear()
            } else {
              x.date_published = null
              x.year = 1970
            }
          })
          const data = rows.concat(response)
          rows = data
          this.count = rows.length
          page++
          await this.$nextTick()
        } while (response.length === limit)
        rows = _.uniqBy(rows, 'id')
        this.cases = rows
        const info = await db.info()
        if (info.doc_count > 0) {
          await db.destroy()
          db = null
          db = new PouchDB('cases')
        }
        await db.bulkDocs(this.cases)
        console.log(this.cases)
      } catch (e) {
        console.log(e)
      }
    },
    reload: async function () {
      this.loadingBackground = true
      try {
        await Promise.all([this.loadRows()])
      } catch (e) {
        this.$logger.warn('load ERROR: ', e)
      }
      this.ready = false
      this.loadingBackground = false
      this.loading = false
      this.ready = true
    }
  }
}
</script>
<style scoped>
#top-menu-bar a:hover {
  font-weight: bold;
}
#top-menu-bar li a {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
}
#top-menu-bar li .active {
  background: #00B8F5;
  font-weight: bold;
}
</style>
